import React from "react";
import { Link, Element, animateScroll as scroll } from "react-scroll";

const Footer = () => {
    const navLinks = [
        {
            label: "Home",
            key: "home",
            path: "/",
        },
        {
            label: "About",
            key: "about",
            path: "/meeting",
        },
        {
            label: "Services",
            path: "/services",
            key: "services",
        },
        {
            label: "Work",
            path: "/work",
            key: "work",
        },
        {
            label: "Reviews",
            path: "/reviews",
            key: "reviews",
        },
        {
            label: "Contact",
            key: "contact",
            path: "/contact",
        },
    ];

    return (
        <div className="">
            <footer className="px-2 shadow-lg relative sm:px-[100px] mx-2 sm:mx-[100px] overflow-hidden rounded-[30px] my-16 flex justify-center h-full py-5">
                <div
                    className="absolute w-full h-full rounded-[30px] z-[-1] opacity-20"
                    style={{
                        backgroundImage: 'url("img/bb.jpeg")',
                        backgroundSize: "cover",
                        backgroundPosition: "left",
                    }}
                ></div>

                <div className="container mx-auto p-4">
                    <div className="sm:flex sm:flex-wrap sm:-mx-4 md:py-4">
                        <div className="px-4 sm:w-1/2 md:w-1/4 xl:w-1/6">
                            <h5 className="text-xl font-bold mb-6">Links</h5>
                            <ul className="list-none footer-links">
                                {navLinks.map((li, i) => (
                                    <Link
                                        activeClass="active"
                                        key={i}
                                        to={li.key}
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={500}
                                    >
                                        <li className="">
                                            <a
                                                href="#"
                                                className="border-b border-solid border-transparent hover:border-purple-800 hover:text-purple-800"
                                            >
                                                {li.label}
                                            </a>
                                        </li>
                                    </Link>
                                ))}
                            </ul>
                        </div>

                        <div className="px-4 mt-4 sm:w-1/3 xl:w-1/6 sm:mx-auto xl:mt-0 xl:ml-auto">
                            <h5 className="text-xl font-bold sm:text-center xl:text-left">
                                Address
                            </h5>
                            <div>Atlanta, Georgia</div>
                            <h5 className="text-xl mt-3 font-bold sm:text-center xl:text-left">
                                Phone
                            </h5>
                            <div>
                                <a href="tel:+971558427792">+971 55 842 7792</a>
                            </div>
                            <h5 className="text-xl mt-3 font-bold sm:text-center xl:text-left">
                                Email
                            </h5>
                            <div>
                                <a href="mailto:samiuddin@sidmillc.com">
                                    samiuddin@sidmillc.com
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="w-full text-center mt-4">
                        All rights reserved © 2024 Sidmi LLC
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
