import React from "react";

import { ThreeDots } from "react-loader-spinner";

const LLCForm = ({ loading, handleSubmit, handleChange, formData }) => {
    return (
        <div className="w-full">
            <form className="w-full" onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 gap-4">
                    <div>
                        <label htmlFor="firstName" className="block mb-2">
                            First Name
                        </label>
                        <input
                            type="text"
                            id="firstName"
                            placeholder="Enter your First name"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:border-theme-color2 focus:outline-none resize-none"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="lastName" className="block mb-2">
                            Last Name
                        </label>
                        <input
                            type="text"
                            id="lastName"
                            placeholder="Enter your last name"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:border-theme-color2 focus:outline-none resize-none"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="email" className="block mb-2">
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            placeholder="Enter your Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:border-theme-color2 focus:outline-none resize-none"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="phone" className="block mb-2">
                            Phone
                        </label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            placeholder="Enter your Phone number with country code"
                            value={formData.phone}
                            onChange={handleChange}
                            className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:border-theme-color2 focus:outline-none resize-none"
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="nationality" className="block mb-2">
                            Nationality
                        </label>
                        <input
                            type="text"
                            id="nationality"
                            placeholder="Enter your Nationality"
                            name="nationality"
                            value={formData.nationality}
                            onChange={handleChange}
                            className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:border-theme-color2 focus:outline-none resize-none"
                            required
                        />
                    </div>
                </div>
                <div className="w-full flex justify-center">
                    <button
                        type="submit"
                        className="py-2 mt-4 hover:shadow-lg px-6 bg-gradient-to-l hover:bg-gradient-to-tl from-primary to-secondary text-white rounded-full transition duration-300 ease-in-out hover:bg-opacity-90"
                    >
                        {loading ? (
                            <ThreeDots
                                visible={true}
                                height="25"
                                width="25"
                                color="#fff"
                                radius="9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        ) : (
                            "Book Consultancy"
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default LLCForm;
