import React from "react";
import { FaRegCheckCircle } from "react-icons/fa";

const About = () => {
    const why = [
        "It's a promise of quality",
        "To provide unparalleled value",
        "Commitment to your success",
        "Satisfaction to our partners",
        "To elevate wholesale standards",
        "To make relation for futher endevours",
    ];

    const why2 = [
        {
            title: "Expertise",
            sub: "Our team of seasoned professionals brings years of experience in business consultancy, ensuring you receive the best advice tailored to your unique situation.",
        },
        {
            title: "Comprehensive Support",
            sub: "From LLC formation to Amazon business setup, we provide end-to-end support to empower you at every step of your entrepreneurial journey.",
        },
        {
            title: "Affordable Consultancy",
            sub: "We believe in making quality consultancy services accessible to all. Our flat fee of $70 USD ensures you receive top-notch guidance without breaking the bank.",
        },
        {
            title: "Customer-Centric Approach",
            sub: "Your success is our priority. We take the time to understand your goals and challenges, offering personalized solutions that set you up for success.",
        },
    ];

    return (
        <div className="flex w-full mt-10">
            <div className="w-[40%] hidden sm:block">
                <img
                    src="img/about.jpg"
                    className="w-full h-[70vh] object-cover"
                />
            </div>
            <div className="sm:w-[60%] sm:h-[70vh] bg-gradient-to-tr from-primary to-secondary">
                <div className="p-10 text-white ">
                    <span
                        className="font-bold relative 
                    text-4xl after:content-normal after:rounded-full 
                    after:absolute after:bottom-[-10px] 
                    after:left-0 after:w-2/3 after:h-1 after:bg-white"
                    >
                        ABOUT US
                    </span>

                    <div className="py-10">
                        At Sidmi LLC, we specialize in providing expert
                        consultancy services to guide you through the process of
                        forming your own Limited Liability Company (LLC) in the
                        United States and the United Kingdom. In addition, we
                        offer comprehensive assistance for entrepreneurs looking
                        to kickstart their Amazon dropshipping and FBA
                        (Fulfillment by Amazon) businesses. Our experienced team
                        is here to simplify the complexities and pave the way
                        for your entrepreneurial journey.
                    </div>

                    <span className="font-bold text-lg">Why Choose Us?</span>

                    <div className="py-4">
                        {why.map((y, i) => (
                            <div
                                key={i}
                                className="flex py-1 gap-2 items-center"
                            >
                                <span>
                                    <FaRegCheckCircle />
                                </span>
                                <span>{y}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
