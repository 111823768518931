import React from "react";

const Feedback = () => {
    const feedback = [
        {
            name: "Roy",
            message:
                "Impressed with the company's professionalism and the consistent high quality of their products.",
            img: "02.jpg",
        },
        {
            name: "Billings",
            message:
                "Exceptional service, timely deliveries, and a reliable partner in our business endeavors.",
            img: "01.jpg",
        },
        {
            name: "Ahmed",
            message:
                "Sidmi's commitment to customer satisfaction and personalized attention has truly set them apart in the industry.",
            img: "03.jpg",
        },
    ];

    return (
        <div className="px-2 sm:px-36 pb-4 sm:pb-36 bg-white">
            <div className="w-full text-center text-4xl font-bold mb-16">
                <span
                    className="relative 
                    after:content-normal after:rounded-full 
                    after:absolute after:bottom-[-10px] 
                    after:bg-gradient-to-tr after:from-primary after:to-secondary
                    after:left-1/2 after:-translate-x-1/2 after:w-2/3 after:hidden sm:after:block after:h-1 after:bg-white after:opacity-70"
                >
                    WHAT OUR CLIENTS SAY
                </span>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-3 justify-center items-center gap-10">
                {feedback.map((fb) => (
                    <div
                        key={fb.name}
                        className="flex gap-4 justify-center items-center"
                    >
                        <img
                            src={`img/testimonials/${fb.img}`}
                            className="rounded-full"
                        />
                        <div className="italic text-sm">
                            {fb.message}
                            <div className="font-bold">- {fb.name}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Feedback;
