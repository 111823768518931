import React, { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import LLCForm from "./LLCForm";
import StripePayout from "./StripePayout";
import axios from "axios";

const FormLLC = ({ isOpen, onClose, selectedConsultancy }) => {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        nationality: "",
    });

    const [clientSecret, setClientSecret] = useState(null);
    const [success, setSuccess] = useState({
        intitalized: false,
        status: false,
    });

    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const LEAD_ROUTE = `${process.env.REACT_APP_API_URL}/web/lead`;

    const handleAddLead = async (e) => {
        e.preventDefault();
        setLoading(true);
        axios
            .post(LEAD_ROUTE, { ...formData, product: selectedConsultancy._id })
            .then((res) => {
                console.log(res.data);
                setClientSecret(res.data.data.client_secret);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    };

    async function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);
        try {
            emailjs
                .send(
                    "service_u5dvgqv",
                    "template_6l0wr7u",
                    formData,
                    "xkJKlWxSWNchoZsAZ"
                )
                .then((result) => {
                    onClose();
                    toast("Thank you, we will reach out to you soon!");
                    setLoading(false);
                });
        } catch (e) {
            setLoading(false);
            onClose();
        }
    }

    return (
        <div
            className={`fixed z-1000 ${
                isOpen ? "grid" : "hidden"
            } left-1/2 -translate-x-1/2 rounded-lg top-32 w-[90%] sm:w-[80%] grid-cols-1 sm:grid-cols-2 bg-slate-100 items-center justify-center `}
        >
            <div
                className="absolute w-full h-full opacity-10 rounded-lg"
                style={{
                    backgroundImage: 'url("img/bsv.jpg")',
                    backgroundSize: "cover",
                    // backop,
                }}
            ></div>

            <div className="w-full hidden sm:flex flex-col justify-center items-center rounded-lg">
                <h2 className="text-3xl text-secondary font-bold mb-4 w-full p-4 uppercase">
                    Book a consultancy from our expert.{" "}
                    {selectedConsultancy?.name}
                </h2>
                <img className="my-10" src="img/agree.svg" />
            </div>

            <div
                className={`
                    w-full shadow-lg bg-white flex rounded-lg h-full transform transition-transform duration-300 ease-in-out
            `}
            >
                <div className="p-6 w-full my-auto">
                    <div
                        onClick={onClose}
                        className="absolute w-10 h-10 flex justify-center items-center 
                     top-[-1rem] right-[-1rem] text-2xl cursor-pointer
                     bg-gradient-to-l hover:bg-gradient-to-tl from-primary to-secondary border
                    rounded-full text-white"
                    >
                        <IoClose />
                    </div>
                    {clientSecret ? (
                        <div>
                            {success.intitalized && success.status ? (
                                <>Successfull!</>
                            ) : success.intitalized && !success.status ? (
                                <>Failed!</>
                            ) : (
                                <StripePayout
                                    clientSecret={clientSecret}
                                    success={success}
                                    setSuccess={setSuccess}
                                />
                            )}
                        </div>
                    ) : (
                        <LLCForm
                            loading={loading}
                            formData={formData}
                            handleChange={handleChange}
                            handleSubmit={handleAddLead}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default FormLLC;
