import React from "react";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";

const images = [
    {
        url: "img/legal.jpg",
        title: "Begin Your Business Journey",
        subtitle: "Expert guidance in forming your LLC seamlessly.",
    },
    {
        url: "img/shopify.jpg",
        title: "E-Commerce Simplified",
        subtitle:
            "Kickstart your online business journey with our expert support.",
    },
    {
        url: "img/sales.jpg",
        title: "Sales Growth Strategies",
        subtitle:
            "Innovative solutions to boost your sales and expand your market presence.",
    },
    {
        url: "img/ship.jpg",
        title: "We are with you",
        subtitle:
            "From initial steps in LLC formation to detailed legal guidance and market entry strategies",
    },
];

const Carousel = () => {
    const [currentImage, setCurrentImage] = React.useState(0);

    const refs = images.reduce((acc, val, i) => {
        acc[i] = React.createRef();
        return acc;
    }, {});

    const scrollToImage = (i) => {
        setCurrentImage(i);
        refs[i].current.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
        });
    };

    const totalImages = images.length;

    const nextImage = () => {
        if (currentImage >= totalImages - 1) {
            scrollToImage(0);
        } else {
            scrollToImage(currentImage + 1);
        }
    };

    const previousImage = () => {
        if (currentImage === 0) {
            scrollToImage(totalImages - 1);
        } else {
            scrollToImage(currentImage - 1);
        }
    };

    const arrowStyle =
        "absolute text-white text-xl z-10 bg-black h-10 w-10 rounded-full opacity-25 flex items-center justify-center";

    const sliderControl = (isLeft) => (
        <button
            type="button"
            onClick={isLeft ? previousImage : nextImage}
            className={`${arrowStyle} ${
                isLeft ? "left-2 sm:left-[-4rem]" : "right-2 sm:right-[-4rem]"
            } top-[80%] sm:top-[50%] translate-y-[-50%]`}
        >
            <span role="img" aria-label={`Arrow ${isLeft ? "left" : "right"}`}>
                {isLeft ? <MdKeyboardArrowLeft /> : <MdKeyboardArrowRight />}
            </span>
        </button>
    );

    return (
        <div className="px-2 sm:px-[100px] bg-white  flex justify-center h-full ">
            <div className="flex justify-center items-center">
                <div className="relative w-full">
                    <div className="absolute w-full h-[500px] flex text-white rounded-[30px] bg-[#0000002f]">
                        <div className="flex flex-col px-2 py-4 sm:px-[20px] sm:py-[50px]">
                            <div className="font-bold text-2xl ">
                                {images[currentImage].title}
                            </div>
                            <div className="font-bold text-lg">
                                {images[currentImage].subtitle}
                            </div>
                        </div>
                    </div>
                    <div className="carousel rounded-[30px]">
                        {sliderControl(true)}
                        {images.map((img, i) => (
                            <div
                                className="w-full flex-shrink-0"
                                key={img.url}
                                ref={refs[i]}
                            >
                                <img
                                    src={img.url}
                                    className="w-full h-[500px] object-cover overflow-hidden"
                                />
                            </div>
                        ))}
                        {sliderControl()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Carousel;
