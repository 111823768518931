import React, { useState } from "react";
import { FaPhone } from "react-icons/fa6";
import emailjs from "@emailjs/browser";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";

const ContactSection = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            emailjs
                .send(
                    "service_u5dvgqv",
                    "template_juqhjag",
                    formData,
                    "xkJKlWxSWNchoZsAZ"
                )
                .then(
                    (result) => {
                        toast("Thank you, we will reach out to you soon!");
                        setLoading(false);
                    },
                    (error) => {
                        setLoading(false);
                    }
                );
        } catch (e) {
            setLoading(false);
        }
    };

    return (
        <section
            className="contact-section relative overflow-hidden bg-cover"
            style={{ backgroundImage: 'url("img/intro-bg.jpg")' }}
        >
            <div className="bg absolute left-0 top-0 h-full w-full bg-gradient-to-l from-primary to-secondary"></div>
            <div className="auto-container relative pt-20 ">
                <div className="outer-box relative">
                    <div className="sm:flex sm:flex-wrap">
                        {/* Form Column */}
                        <div className="form-column mb-28 sm:mb-0 sm:p-20">
                            <div className="px-4 lg:pr-0">
                                {/* Contact Form */}
                                <div className="contact-form wow fadeInLeft w-full bg-[#ffffff64] sm:p-16 rounded-lg shadow-md">
                                    <div className="mb-10">
                                        <h2 className="font-bold text-white text-2xl sm:text-5xl p-2">
                                            Your Solution Starts Here: <br />{" "}
                                            Get in Touch!
                                        </h2>
                                    </div>

                                    <form
                                        id="looking_quality"
                                        name="looking_quality"
                                        data-wow-duration="1.5s"
                                        className=""
                                        onSubmit={handleSubmit}
                                    >
                                        <div className="grid grid-cols-1 gap-6 p-2">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    placeholder="Your Name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    required
                                                    className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:border-theme-color2 focus:outline-none"
                                                />
                                            </div>

                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    placeholder="Email Address"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                    className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:border-theme-color2 focus:outline-none"
                                                />
                                            </div>

                                            <div className="form-group">
                                                <textarea
                                                    name="message"
                                                    placeholder="Write a Message"
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                    required
                                                    className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:border-theme-color2 focus:outline-none resize-none h-36"
                                                ></textarea>
                                            </div>

                                            <div className="mb-3 text-center">
                                                <input
                                                    name="form_botcheck"
                                                    className="form-control"
                                                    type="hidden"
                                                    value=""
                                                />
                                                <button
                                                    type="submit"
                                                    name="submit"
                                                    id="message"
                                                    value="submit"
                                                    className="py-2 hover:shadow-lg px-6 bg-gradient-to-l hover:bg-gradient-to-tl from-primary to-secondary text-white rounded-full transition duration-300 ease-in-out hover:bg-opacity-90"
                                                    data-loading-text="Please wait..."
                                                >
                                                    {loading ? (
                                                        <ThreeDots
                                                            visible={true}
                                                            height="25"
                                                            width="25"
                                                            color="#fff"
                                                            radius="9"
                                                            ariaLabel="three-dots-loading"
                                                            wrapperStyle={{}}
                                                            wrapperClass=""
                                                        />
                                                    ) : (
                                                        <span className="font-bold">
                                                            Send message
                                                        </span>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="image-column col-lg-6 -right-1/5 col-md-12 relative">
                            <div className="inner-column">
                                <figure className="image">
                                    <img
                                        src="img/resource/image-2.png"
                                        alt=""
                                    />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="absolute m-2 right-5 bottom-0 flex gap-2 items-center">
                    <div className="">
                        <div className="text-primary text-xl p-2 rounded-full bg-white">
                            <FaPhone />
                        </div>
                    </div>
                    <div className="text-white font-bold">
                        <div className="text-base leading-6">
                            Let’s Work Together
                        </div>
                        <h4 className="">
                            <a
                                className="count"
                                style={{ fontFamily: "Syne, sans-serif" }}
                                href="tel:+971558427792"
                            >
                                +971558427792
                            </a>
                        </h4>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactSection;
