import React, { useEffect, useState } from "react";
// import { Link, useLocation } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { Link, Element, animateScroll as scroll } from "react-scroll";
import { IoIosArrowRoundForward } from "react-icons/io";

const Header = () => {
    const navLinks = [
        {
            label: "Home",
            key: "home",
            path: "/",
        },
        {
            label: "About",
            key: "about",
            path: "/meeting",
        },
        {
            label: "Services",
            path: "/services",
            key: "services",
        },
        {
            label: "Work",
            path: "/work",
            key: "work",
        },
        {
            label: "Reviews",
            path: "/reviews",
            key: "reviews",
        },
        {
            label: "Contact",
            key: "contact",
            path: "/contact",
        },
    ];

    const handleScroll = () => {
        const headerMain = document.getElementById("headerMain");
        if (headerMain && headerMain !== null) {
            if (headerMain.offsetTop) {
                headerMain.classList.add("headerShadow");
            } else {
                headerMain.classList.remove("headerShadow");
            }
        }
    };
    window.addEventListener("scroll", handleScroll, true);

    const [activeTab, setActiveTab] = useState("offices");
    const [navExpanded, setNavExpanded] = useState(false);

    return (
        <div
            className="z-[10000] sticky top-0 left-0 bg-white flex justify-center items-center"
            id="headerMain"
        >
            <div className="container w-[100vw] flex justify-between items-left sm:gap-10 p-3 py-4 sm:px-8 flex-col sm:flex-row">
                {/* <Link to="/"> */}
                <div className="w-full flex justify-between items-center">
                    <img
                        className="h-[35px] sm:h-[55px]"
                        src="img/sidmi_logo.png"
                    />
                    <div
                        className="sm:hidden pr-2"
                        onClick={() => setNavExpanded((prev) => !prev)}
                    >
                        <RxHamburgerMenu size={30} color="#000" />
                    </div>
                </div>
                {/* </Link> */}
                <div className="flex justify-center items-center">
                    <nav>
                        <ul
                            className={`flex gap-2 transition-all items-center ease-in-out text-sm whitespace-nowrap duration-300 flex-col sm:flex-row h-0 sm:h-full opacity-0 sm:opacity-100 ${
                                navExpanded && "h-full opacity-100"
                            }`}
                        >
                            {navLinks.map((nav, i) => (
                                <Link
                                    key={i}
                                    activeClass="active"
                                    to={nav.key}
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                >
                                    <li
                                        key={i}
                                        className="uppercase w-fit transition-all flex justify-center ease-in-out cursor-pointer  px-4 py-2 rounded-full mt-3 sm:mt-0 relative group"
                                        onClick={() => {
                                            setActiveTab(nav.key);
                                            setNavExpanded(false);
                                        }}
                                    >
                                        {nav.label}
                                        <span className="absolute bottom-[-8px] h-[2px] bg-gradient-to-r from-primary to-secondary w-0 group-hover:w-full transition-all"></span>
                                    </li>
                                </Link>
                            ))}
                            <li>
                                <Link
                                    activeClass="active"
                                    to="consultancy"
                                    spy={true}
                                    smooth={true}
                                    offset={-600}
                                    duration={500}
                                >
                                    <div
                                        onClick={() => {
                                            setNavExpanded(false);
                                        }}
                                        className={`
                        cursor-pointer group rounded-full justify-center items-center 
                    font-bold text-nowrap bg-gradient-to-tr from-primary to-secondary flex sm:hidden
                    py-2 px-4 text-white transition-all ${
                        navExpanded && "h-full flex"
                    }
                        `}
                                    >
                                        <span>Consult for LLC Formation</span>
                                        <span className="text-3xl group-hover:translate-x-[15px] transition-all">
                                            <IoIosArrowRoundForward />
                                        </span>
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                    <Link
                        activeClass="active"
                        to="consultancy"
                        spy={true}
                        smooth={true}
                        offset={-150}
                        duration={500}
                    >
                        <div
                            // onClick={() => setIsFormLlcOpen(true)}
                            className={`
                        cursor-pointer group rounded-full justify-center items-center 
                    font-bold text-nowrap bg-gradient-to-tr from-primary to-secondary hidden sm:flex
                    py-2 px-4 text-white transition-all ${
                        navExpanded && "h-full flex"
                    }
                        `}
                        >
                            <span>Consult for LLC Formation</span>
                            <span className="text-3xl group-hover:translate-x-[15px] transition-all">
                                <IoIosArrowRoundForward />
                            </span>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Header;
