import React from "react";
import Feedback from "./Feedback";

const Services = () => {
    const services = [
        {
            title: "Get Expert Consultancy",
            subtitle:
                "Our experienced consultants, with proven expertise in LLC formation and Amazon business, will provide a comprehensive overview of the process. From initial steps in LLC formation to detailed legal guidance and market entry strategies.",
            img: "quality.png",
        },
        {
            title: "Legal Guidance",
            subtitle:
                "Our consultancy provides vital legal guidance for LLC formation and navigating the intricacies of a successful Amazon business. From understanding product listings to ensuring compliance with marketplace policies, we've got you covered in the dynamic e-commerce landscape.",
            img: "pricing.png",
        },
        {
            title: "Your Demand Our Responsiblity",
            subtitle:
                "We take pride in our customer-centric approach, where your needs and requirements become our utmost priority. Our dedicated team is committed to understandings.",
            img: "responsibility.png",
        },
        {
            title: "Commitment to your success",
            subtitle:
                "At Sidmi LLC, our commitment to your success is at the core of everything we do. We understand that your triumphs drive our own, and that's why we prioritize every aspect of our business to ensure it aligns seamlessly with your goals.",
            img: "success.png",
        },
        {
            title: "To Provide Unparrel value",
            subtitle:
                "We go beyond merely supplying products; we strive to bring exceptional worth to every transaction. Through a combination of high-standard products, flexible pricing options, and a commitment to your success.",
            img: "value.png",
        },
        {
            title: "Commitment translates into tangible support",
            subtitle:
                "Choose Sidmi for a partnership where commitment transforms into substantial, hands-on support, ensuring your success is not just a goal but a reality.",
            img: "support.png",
        },
    ];

    return (
        <div className="relative">
            <div
                className="absolute w-full h-full opacity-20"
                style={{
                    backgroundImage: 'url("img/bsv.jpg")',
                    backgroundSize: "cover",
                    // backop,
                }}
            ></div>

            <div className="mx-4 sm:mx-16 py-16">
                <div className="w-full text-center text-4xl font-bold mb-16">
                    <span
                        className="relative 
                    after:content-normal after:rounded-full 
                    after:absolute after:bottom-[-10px] 
                    after:bg-gradient-to-tr after:from-primary after:to-secondary
                    after:left-1/2 after:-translate-x-1/2 after:w-2/3 after:h-1 after:bg-white after:opacity-70"
                    >
                        Our Services
                    </span>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-28 gap-y-10 sm:mx-10 sm:my-10">
                    {services.map((srv, i) => (
                        <div
                            key={i}
                            className="shadow backdrop-blur-lg hover:text-white hover:scale-105 transition-all p-10 text-center rounded-lg relative group overflow-hidden"
                        >
                            <div
                                key={i}
                                className="flex flex-col gap-3 transition-all"
                            >
                                <div className="w-full z-[9999] flex justify-center">
                                    <img
                                        src={`img/services/${srv.img}`}
                                        className="w-[90px]"
                                    />
                                </div>
                                <h3 className="text-center z-[9999]  font-bold text-xl">
                                    {srv.title}
                                </h3>
                                <p className="z-[9999]">{srv.subtitle}</p>
                            </div>
                            <div
                                className="absolute z-0 top-0 left-0 group-hover:h-full 
                                rounded-full w-0 h-0 group-hover:w-full scale-150  bg-gradient-to-tr from-primary to-accent transition-all duration-500"
                            ></div>
                        </div>
                    ))}
                </div>
            </div>
            <Feedback />
        </div>
    );
};

export default Services;
