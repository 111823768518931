import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaCheckCircle } from "react-icons/fa";
import { IoCloseCircle } from "react-icons/io5";

const SelectConsultancies = ({ setIsFormLlcOpen, selectConsultancy }) => {
    const [products, setProducts] = useState([]);

    const fetchConsultancy = async () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/web/product`)
            .then((res) => {
                if (res.data.status) {
                    setProducts(res.data.data);
                }
            });
    };

    useEffect(() => {
        fetchConsultancy();
    }, []);

    return (
        <div className="rounded-lg items-center justify-center px-10 mb-10">
            <div className="my-10 sm:px-36">
                <h2 className="text-center uppercase text-primary text-4xl font-bold tracking-wider">
                    Consult for LLC Formation
                </h2>
                <p className="text-slate-600 text-xl my-2 text-center">
                    Take the first step toward building your successful business
                    today – book a consultancy with SIDMI LLC and unlock the
                    full potential of your venture.
                </p>
            </div>

            <div className="w-full bg-white rounded-lg h-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 justify-center gap-10">
                {products.map((pr) => (
                    <div
                        key={pr._id}
                        className="border relative backdrop-blur-lg border-primary shadow shadow-primary rounded-lg p-5 pt-6"
                    >
                        <h3 className="text-center font-bold uppercase text-2xl">
                            {pr.name}
                        </h3>
                        <div className="mt-5">
                            {pr.features.map((feat) => (
                                <div className="flex gap-2 mt-4 items-top">
                                    <div className="pt-[2px]">
                                        {feat.available ? (
                                            <span className="text-green-500 text-lg">
                                                {" "}
                                                <FaCheckCircle />
                                            </span>
                                        ) : (
                                            <span className="text-red-500 text-xl">
                                                {" "}
                                                <IoCloseCircle />
                                            </span>
                                        )}
                                    </div>
                                    <div
                                        key={feat}
                                        className={
                                            !feat.available && "text-gray-400"
                                        }
                                    >
                                        <h3 className="uppercase font-bold">
                                            {feat.name}
                                        </h3>
                                        <p>{feat.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div
                            onClick={() => {
                                setIsFormLlcOpen(true);
                                selectConsultancy(pr);
                            }}
                            className="cursor-pointer mt-10 group rounded-full justify-center items-center 
                                font-bold text-nowrap bg-gradient-to-tr from-primary to-secondary flex
                                p-4 text-white transition-all hover:bg-gradient-to-r"
                        >
                            <span>
                                BOOK NOW FOR{" "}
                                <span className="text-xl ml-2">
                                    ${pr.price}
                                </span>
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SelectConsultancies;
