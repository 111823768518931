import React from "react";
import { FaCartPlus } from "react-icons/fa";
import { MdDiscount } from "react-icons/md";
import { BsStars } from "react-icons/bs";
import { FaHandshake } from "react-icons/fa";

import { MdOutlineAddBusiness } from "react-icons/md";
import { TbTruckDelivery } from "react-icons/tb";

const OurOffer = () => {
    const offers = [
        {
            icon: <MdOutlineAddBusiness />,
            title: "LLC Formation Services",
            text: "Expert guidance on choosing the right business structure. Assistance with legal documentation and filing requirements.",
        },
        {
            icon: <BsStars />,
            title: "US and UK Expansion Support",
            text: "Comprehensive assistance for establishing your presence in the US and UK markets. Insightful market analysis and strategic planning to ensure a smooth entry.",
        },
        {
            icon: <TbTruckDelivery />,
            title: "Amazon Dropshipping Consultancy",
            text: "Detailed insights into the world of dropshipping on the Amazon platform. Strategies for product selection, supplier collaboration, and order fulfillment.",
        },
        {
            icon: <FaHandshake />,
            title: "FBA Business Setup",
            text: "Guidance on utilizing Amazon's FBA program for streamlined order processing.",
        },
    ];

    return (
        <div className="p-6">
            <div className="flex flex-col sm:flex-row py-4 px-3 justify-center gap-10 items-center text-center">
                {offers.map((off, i) => (
                    <div key={i} className="flex flex-col gap-3">
                        <div className="text-white text-[30px] w-full flex justify-center">
                            <div className="p-6 bg-gradient-to-r from-primary to-accent rounded-full text-center flex justify-center items-center">
                                {off.icon}
                            </div>
                        </div>
                        <h3 className="text-center font-bold text-xl">
                            {off.title}
                        </h3>
                        <p>{off.text}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OurOffer;
