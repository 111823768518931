import React from "react";

const Brands = () => {
    const brands = ["amazon.png", "walmart.png", "shopify.png", "ebay.png"];

    return (
        <div className="flex justify-center gap-2 sm:gap-14 px-2 sm:px-[100px] max-w-full">
            {brands.map((br) => (
                <img
                    key={br}
                    src={`img/brands/${br}`}
                    className={`${
                        br === "amazon.png"
                            ? "w-[50px] sm:w-[120px]"
                            : "w-[70px] sm:w-[150px]"
                    }  object-contain`}
                />
            ))}
        </div>
    );
};

export default Brands;
