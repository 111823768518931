import React, { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ThreeDots } from "react-loader-spinner";

import {
    useStripe,
    useElements,
    PaymentElement,
} from "@stripe/react-stripe-js";

const CheckoutForm = ({ success, setSuccess }) => {
    const stripe = useStripe();
    const elements = useElements();

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        setSuccess({ ...success, intitalized: true });

        if (!stripe || !elements) {
            setLoading(false);
            return;
        }

        const result = await stripe.confirmPayment({
            elements,
            redirect: "if_required",
        });
        setLoading(false);

        if (result.error) {
            console.log(result.error.message);
            setLoading(false);
            setSuccess({ success: false, intitalized: true });
        } else {
            if (result.paymentIntent.status === "succeeded")
                console.log({ result });
            setSuccess({ success: true, intitalized: true });
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <div className="flex w-full justify-center">
                <button disabled={!stripe}>
                    <div
                        className="cursor-pointer w-[100px] mt-10 group rounded-full justify-center items-center 
                                font-bold text-nowrap bg-gradient-to-tr from-primary to-secondary flex
                                px-4 py-2 text-white transition-all hover:bg-gradient-to-r"
                    >
                        <span>
                            {loading ? (
                                <ThreeDots
                                    visible={true}
                                    height="25"
                                    width="25"
                                    color="#fff"
                                    radius="9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />
                            ) : (
                                "Submit"
                            )}
                        </span>
                    </div>
                </button>
            </div>
        </form>
    );
};

const stripePromise = loadStripe(
    "pk_test_51Obo0FKAP2cbJqdb7E5GCFofIXV9cTmdavCjMQePpANQFbdHKE4xhJEdYDNQ9UAhlHIwOwleseayxiwjHZvyUppD00aqYZzEIp"
);

const StripePayout = ({ clientSecret, success, setSuccess }) => {
    if (!clientSecret) return <></>;

    const options = {
        // passing the client secret obtained from the server
        clientSecret: clientSecret,
    };

    return (
        <Elements stripe={stripePromise} options={options}>
            <CheckoutForm success={success} setSuccess={setSuccess} />
        </Elements>
    );
};

export default StripePayout;
