import logo from "./logo.svg";
import "./App.css";
import Layout from "./Layout";
import Carousel from "./components/Carousal";
import Brands from "./components/Brands";
import OurOffer from "./components/OurOffer";
import ContactSection from "./components/Contact";
import About from "./components/About";
import Services from "./components/Services";
import { Element } from "react-scroll";
import { ThreeDots } from "react-loader-spinner";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import SelectConsultancies from "./components/SelectConsultancies";
import FormLLC from "./components/FormLLC";
import "react-toastify/dist/ReactToastify.css";

function App() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    const [selectedConsultancy, setSelectedConsultancy] = useState();
    const [isFormLlcOpen, setIsFormLlcOpen] = useState(false);

    return (
        <div className="">
            <div
                className={`
                absolute ${
                    loading ? "flex" : "hidden"
                } flex justify-center items-center top-0 left-0 w-screen h-screen 
                bg-gradient-to-tl from-primary to-secondary z-[1000000]
            `}
            >
                <div className="flex flex-col justify-center items-center">
                    <div className="">
                        <ThreeDots
                            visible={true}
                            height="80"
                            width="80"
                            color="#fff"
                            radius="9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                </div>
            </div>
            <ToastContainer position="bottom-right" />

            <Layout>
                <Element name="home" className="element">
                    <Carousel />
                </Element>

                <Element name="brands" className="element">
                    <Brands />
                </Element>

                <Element name="work" className="element">
                    <OurOffer />
                </Element>

                <Element name="about" className="element">
                    <About />
                </Element>

                <Element name="services" className="element">
                    <Services />
                </Element>

                <Element name="consultancy" className="element">
                    <SelectConsultancies
                        setIsFormLlcOpen={setIsFormLlcOpen}
                        selectConsultancy={(con) => setSelectedConsultancy(con)}
                    />
                </Element>

                {/* <Feedback /> */}
                <Element name="contact" className="element">
                    <ContactSection />
                </Element>
            </Layout>
            <div
                onClick={() => setIsFormLlcOpen(false)}
                className={`fixed top-0 left-0 w-screen h-screen bg-black opacity-60 ${
                    isFormLlcOpen ? "block" : "hidden"
                }`}
            ></div>
            <FormLLC
                isOpen={isFormLlcOpen}
                onClose={() => setIsFormLlcOpen(false)}
                selectedConsultancy={selectedConsultancy}
            />
        </div>
    );
}

export default App;
